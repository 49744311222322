import React, { useState } from "react";
import { navigate } from "gatsby";
import Layout from "../components/general/Layout";

const Scanner = () => {
    const [result, setResult] = useState("Scanning ...");

    const handleScan = data => {
        if (data) {
            setResult(`result: ${data}`);
            // navigate(whitelisted(data))
        }
    };

    const handleError = err => {
        // We should look at errorBoundaries, see https://reactjs.org/docs/error-boundaries.html
        console.error(err);
    };
    /* Hack to let gatsby build without causing its compile time SSR to error
     * see this https://www.gatsbyjs.org/docs/debugging-html-builds/#how-to-check-if-window-is-defined
     */
    if (typeof window !== `undefined`) {
        const QrReader = require("react-qr-reader");
        return (
            <Layout>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            width: "50%",
                            height: "50%",
                            margin: "auto",
                            position: "absolute",
                            top: "25%",
                            transform: "translate(25%, -25%)"
                        }}
                    >
                        <QrReader
                            delay={300}
                            facingMode="environment"
                            showViewFinder="true"
                            resolution={600}
                            style={{
                                height: "50%",
                                width: "50%"
                            }}
                            onError={handleError}
                            onScan={handleScan}
                        />
                    </div>
                    <div
                        style={{
                            margin: "auto",
                            position: "absolute",
                            top: "70%"
                        }}
                    >
                        <p>{result}</p>
                    </div>
                </div>
            </Layout>
        );
    }
    return (
        <Layout>
            <div></div>
        </Layout>
    );
};

export default Scanner;
